import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { Studio } from '../../services/studios/studios.types';
import StudiosService from '../../services/studios/studios.service';
import { DateTime } from 'luxon';
import nullIfEmpty from '../../utils/nullIfEmpty';

type DialogAddEditAction = {
  mode: 'add',
} | {
  mode: 'edit',
  id: number,
  name: string,
  description: string,
  address: string;
  instagram: string;
  whatsapp: string;
  phoneNumber: string;
  rules: string;
  timezone: string;
  workTimeStart: string;
  workTimeEnd: string;
};

export type StudiosPageState = {
  data: Studio[],
  showQrCode: boolean,
  qrCodeForPrint: string,
  dialogs: {
    view: {
      open: boolean;
      studio: Studio;
    },
    addEdit: {
      open: boolean;
      mode: 'add' | 'edit',
      success: boolean;
      inputs: {
        id: number | null;
        name: string;
        description: string;
        address: string;
        instagram: string | null,
        whatsapp: string | null,
        telegram: string | null,
        phoneNumber: string | null,
        rules: string;
        workTimeStart: string;
        workTimeEnd: string;
        cancellationDeadline: number;
        timezone: string;
        photo: string;
      }
    },
    delete: {
      open: boolean;
      deletingId: number | null;
    },
  },
};

const initialState: StudiosPageState = {
  data: [],
  showQrCode: false,
  qrCodeForPrint: '',
  dialogs: {
    view: {
      open: false,
      studio: {} as Studio,
    },
    addEdit: {
      open: false,
      mode: 'add',
      success: false,
      inputs: {
        id: null,
        name: '',
        photo: '',
        description: '',
        address: '',
        instagram: '',
        whatsapp: '',
        telegram: '',
        phoneNumber: '',
        rules: '',
        workTimeStart: '',
        workTimeEnd: '',
        cancellationDeadline: 0,
        timezone: '',
      }
    },
    delete: {
      open: false,
      deletingId: null,
    },
  },
};

export const fetchStudios = createAsyncThunk<Studio[]>(
'services/fetchStudios',
async (_, { rejectWithValue }) => {
  try {
    const response = await StudiosService.getList();
    return response;
  } catch (error: any) {
    return rejectWithValue('Error while fetch studios');
  }
});

const StudiosPageSlice = createSlice<StudiosPageState, SliceCaseReducers<StudiosPageState>>({
  name: 'studios',
  initialState,
  reducers: {
    closeStudioDialogAddEdit: (state) => {
      state.dialogs.addEdit.open = false;
    },
    openStudioDialogAddEdit: (state, action: PayloadAction<DialogAddEditAction>) => {
      state.dialogs.addEdit.open = true;
      state.dialogs.addEdit.mode = action.payload.mode;

      if (action.payload.mode === 'add') {
        Object.assign(state.dialogs.addEdit.inputs, initialState.dialogs.addEdit.inputs);
      } else {
        Object.assign(state.dialogs.addEdit.inputs, {
          ...initialState.dialogs.addEdit.inputs,
          ...action.payload,
          workTimeStart: DateTime.fromISO(action.payload.workTimeStart).toFormat('HH:mm'),
          workTimeEnd: DateTime.fromISO(action.payload.workTimeEnd).toFormat('HH:mm'),
        });
      }
    },
    closeStudioDialogDelete: (state) => {
      state.dialogs.delete.open = false;
    },
    openStudioDialogDelete: (state, action: PayloadAction<number>) => {
      state.dialogs.delete.deletingId = action.payload;
      state.dialogs.delete.open = true;
    },
    setInputName: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.name = action.payload;
    },
    setInputDescription: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.description = action.payload;
    },
    setInputAddress: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.address = action.payload;
    },
    setInputInstagram: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.instagram = nullIfEmpty(action.payload);
    },
    setInputWhatsapp: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.whatsapp = nullIfEmpty(action.payload);
    },
    setInputTelegram: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.telegram = nullIfEmpty(action.payload);
    },
    setInputPhoneNumber: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.phoneNumber = nullIfEmpty(action.payload);
    },
    setInputRules: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.rules = action.payload;
    },
    setInputTimezone: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.timezone = action.payload;
    },
    setInputWorkTimeStart: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.workTimeStart = action.payload;
    },
    setInputWorkTimeEnd: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.workTimeEnd = action.payload;
    },
    setShowQrCode: (state, action: PayloadAction<boolean>) => {
      state.showQrCode = action.payload;
    },
    setQrCodeForPrint: (state, action: PayloadAction<string>) => {
      state.qrCodeForPrint = action.payload;
    },
    setImage: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.photo = action.payload;
    },
    setInputCancellationDeadline: (state, action: PayloadAction<string>) => {
      state.dialogs.addEdit.inputs.cancellationDeadline = Number(action.payload);
    },
    openStudioView: (state, action: PayloadAction<Studio>) => {
      state.dialogs.view.open = true;
      state.dialogs.view.studio = action.payload;
    },
    closeStudioView: (state, action) => {
      state.dialogs.view.open = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudios.fulfilled, (state: StudiosPageState, action) => {
        if (JSON.stringify(state.data) !== JSON.stringify(action.payload)) {
          state.data = action.payload;
        }
      });
  },
});

const studiosReducer = StudiosPageSlice.reducer;

export const {
  setInputName,
  setInputDescription,
  setInputAddress,
  setInputInstagram,
  setInputWhatsapp,
  setInputPhoneNumber,
  setInputTelegram,
  setInputRules,
  setInputTimezone,
  setInputWorkTimeStart,
  setInputWorkTimeEnd,
  openStudioDialogAddEdit,
  closeStudioDialogAddEdit,
  openStudioView,
  closeStudioView,
  closeStudioDialogDelete,
  openStudioDialogDelete,
  setShowQrCode,
  setQrCodeForPrint,
  setImage,
  setInputCancellationDeadline,
} = StudiosPageSlice.actions;

export default studiosReducer;
