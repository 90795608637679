import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { closeDialogCheck, setInputEmailCheckUser } from '../clientsPage.slice';
import ClientsService from '../../../services/clients/clients.service';
import { CheckClientResponse } from '../../../services/clients/clients.types';
import { openError, toggleDialogInfo } from '../../../components/dialog/dialogInfo.slice';
import { RootState } from '../../../store/store';
import validateForm from '../../../utils/validation/validateForm';
import processRequestError from '../../../utils/processRequestError';

export const ClientCheckDialog = (props: { clientCheckCallBack: (result: CheckClientResponse) => any }) => {
  const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch();

  const { openDialog, email } = useSelector((state: RootState) => state.clients.dialogs.check);
  const { studioId } = useSelector((state: RootState) => state.app);

  const dialogClose = () => {
    dispatch(closeDialogCheck({}));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!validateForm(e)) return;
    if (!studioId) { dispatch(openError('Studio not found')); return; }

    try {
      const result = await ClientsService.check(email, studioId);
      props.clientCheckCallBack(result);
    } catch (err: unknown) {
      dispatch(toggleDialogInfo({
        open: true,
        type: 'error',
        message: `Error while check client: ${processRequestError(err)}`,
      }));
    }
  };

  return (
    <Dialog open={openDialog} onClose={dialogClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add client</DialogTitle>
        <DialogContent>
          <TextField
            label="E-mail"
            variant="outlined"
            value={email}
            onChange={(e) => dispatch(setInputEmailCheckUser(e.target.value))}
            sx={{ marginTop: 1, width: '300px' }}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">Check client</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

