import { AxiosError } from 'axios';

const processRequestError = (error: unknown): string => {
  let message = `${error}`;
  if (error instanceof AxiosError && 'response' in error) {
    message = error.response?.data?.message?.[0];
  } else if (error instanceof Error) {
    message = error.message;
  }

  return message;
};

export default processRequestError;

