import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { closeStudioView, openStudioDialogAddEdit, StudiosPageState } from '../studiosPage.slice';
import { RootState } from '../../../store/store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { Studio } from '../../../services/studios/studios.types';

export const StudioDialogView = () => {
  const dispatch: ThunkDispatch<StudiosPageState, any, AnyAction> = useDispatch();
  const { open: openDialogView } = useSelector((state: RootState) => state.studios.dialogs.view);
  const studio = useSelector((state: RootState) => state.studios.dialogs.view.studio) as Studio;
  const { name, timezone, photo, cancellationDeadline, workTimeStart, workTimeEnd, address, instagram, whatsapp, telegram, phoneNumber, description, rules } = studio;

  const dialogClose = () => { dispatch(closeStudioView({})) };
  const handleEdit = () => {
    dialogClose();
    dispatch(openStudioDialogAddEdit({ mode: 'edit', ...studio }));
  };

  return (
      <Dialog open={openDialogView} onClose={dialogClose} sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px",
          },
        },
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '16px', paddingTop: '8px' }}>
          <DialogTitle>{name}</DialogTitle>
          <Box>
            <Button variant="contained" color="primary" onClick={handleEdit}>Edit</Button>
          </Box>
        </Box>
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <img src={(photo !== '' && photo) || '/logo96.png'} style={{ maxWidth: '400px', height: 'auto' }} />
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Box sx={{ marginBottom: '8px' }}><strong>Address:</strong> {address}</Box>
                    <Box sx={{ marginBottom: '8px' }}><strong>Timezone:</strong> {timezone}</Box>
                    <Box sx={{ marginBottom: '8px' }}><strong>Working hours:</strong> {`${workTimeStart} - ${workTimeEnd}`}</Box>
                    <Box sx={{ marginBottom: '8px' }}><strong>Cancel deadline:</strong> {cancellationDeadline}</Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ marginBottom: '8px' }}><strong>Instagram:</strong> {instagram}</Box>
                    <Box sx={{ marginBottom: '8px' }}><strong>Whatsapp:</strong> {whatsapp}</Box>
                    <Box sx={{ marginBottom: '8px' }}><strong>Telegram:</strong> {telegram}</Box>
                    <Box sx={{ marginBottom: '8px' }}><strong>Phone:</strong> {phoneNumber}</Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h4>Description:</h4>
                <p>{description}</p>
              </Grid>
              <Grid item xs={12}>
                <h4>Rules:</h4>
                <p>{rules}</p>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
  );
};
