import { createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import clientsService from '../../services/clients/clients.service';
import { InitialValues } from '../../types/types';
import { DateTime } from 'luxon';
import { Subscription } from '../subscriptions/subscriptions.types';
import { Booking } from '../../services/bookings/bookings.types';
import { ClientExtended } from '../../services/clients/clients.types';
import { Pricelist } from '../../services/pricelist/pricelist.types';
import { ClientGroup } from '../../services/clientGroups/clientGroups.types';

export type ClientCardPageState = {
  clientId: number | '';
  backPage: string | null;
  tab: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  instagram: string;
  whatsapp: string;
  telegram: string;
  birthDate?: string; // такое неудачное название поля из-за бэка :(
  subscriptions: Subscription[],
  bookings: Booking[],
  groups: ClientGroup[],
  dialogs: {
    subscriptionAdd: {
      isOpen: boolean;
      inputs: {
        id: number | '',
        serviceGroupId: number | '',
        pricelistId: number | '';
        pricelists: Pricelist[],
        price: number | '',
        name: string,
        phone: string,
        email: string,
        comment: string,
        start: string,
        end: string,
      },
      meta: {
        durationUnit: 'day' | 'month' | 'unlimited',
        durationValue: number | null,
      }
    }
  }
};

const initialState: ClientCardPageState = {
  clientId: InitialValues.EMPTY,
  backPage: null,
  name: '',
  surname: '',
  email: '',
  phone: '',
  instagram: '',
  whatsapp: '',
  telegram: '',
  birthDate: undefined,
  subscriptions: [],
  bookings: [],
  groups: [],
  tab: '1',
  dialogs: {
    subscriptionAdd: {
      isOpen: false,
      inputs: {
        id: '',
        serviceGroupId: InitialValues.EMPTY,
        pricelistId: InitialValues.EMPTY,
        pricelists: [],
        price: '',
        name: '',
        phone: '',
        email: '',
        comment: '',
        start: DateTime.now().toISODate(),
        end: '',
      },
      meta: {
        durationUnit: 'unlimited',
        durationValue: null,
      }
    },
  },
};

export const loadClientData = createAsyncThunk<ClientExtended, number>(
  'services/loadClient',
  async (clientId, { rejectWithValue }) => {
    try {
      const response = await clientsService.getOne(clientId);
      return response;
    } catch (error: any) {
      return rejectWithValue('Error while load client data');
    }
  });

const usersPageSlice = createSlice<ClientCardPageState, SliceCaseReducers<ClientCardPageState>>({
  name: 'clients',
  initialState,
  reducers: {
    toggleDialogSubscriptionAdd: (state, action: PayloadAction<boolean>) => {
      state.dialogs.subscriptionAdd.isOpen = action.payload;
      if (!action.payload) {
        Object.assign(state.dialogs.subscriptionAdd.inputs, initialState.dialogs.subscriptionAdd.inputs);
      }
    },
    setClientId: (state, action: PayloadAction<number>) => {
      state.clientId = action.payload;
    },
    setTab: (state, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    setInputServiceGroupId: (state, action: PayloadAction<string>) => {
      state.dialogs.subscriptionAdd.inputs.serviceGroupId = Number(action.payload);
    },
    setInputPricelistId: (state, action: PayloadAction<number>) => {
      state.dialogs.subscriptionAdd.inputs.pricelistId = action.payload;
    },
    setBackPage: (state, action: PayloadAction<string>): void => {
      state.backPage = action.payload;
    },
    setPricelist: (state, action: PayloadAction<number>) => {
      state.dialogs.subscriptionAdd.inputs.price = action.payload;
    },
    setStart: (state, action: PayloadAction<string>) => {
      state.dialogs.subscriptionAdd.inputs.start = action.payload;
    },
    setEnd: (state, action: PayloadAction<string>) => {
      state.dialogs.subscriptionAdd.inputs.end = action.payload;
    },
    setComment: (state, action: PayloadAction<string>) => {
      state.dialogs.subscriptionAdd.inputs.comment = action.payload;
    },
    setInputPrice: (state, action: PayloadAction<string>) => {
      state.dialogs.subscriptionAdd.inputs.price = Number(action.payload);
    },
    setDuration: (state, action: PayloadAction<{ unit: 'day' | 'month' | 'unlimited', value: number | null }>) => {
      state.dialogs.subscriptionAdd.meta.durationUnit = action.payload?.unit ?? 'unlimited';
      state.dialogs.subscriptionAdd.meta.durationValue = action.payload?.value ?? null;
    },
    setPricelists: (state, action: PayloadAction<Pricelist[]>) => {
      state.dialogs.subscriptionAdd.inputs.pricelists = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(loadClientData.fulfilled, (state: ClientCardPageState, action) => {
          state.name = action.payload.name;
          state.surname = action.payload.surname;
          state.email = action.payload.email;
          state.phone = action.payload.phoneNumber;
          state.telegram = action.payload.telegram;
          state.whatsapp = action.payload.whatsapp;
          state.instagram = action.payload.instagram;
          state.birthDate = action.payload.birthDate;

          state.subscriptions = action.payload.subscriptions;
          state.bookings = action.payload.bookings.map((booking: any) => ({
            id: booking.id,
            date: booking.event.date,
            start: booking.event.start,
            end: booking.event.end,
            serviceId: booking.serviceId,
            specialistId: booking.specialistId,
            roomId: booking.roomId,
          }));
          state.groups = action.payload.groups ?? [];
        });
  },
});

const clientCardReducer = usersPageSlice.reducer;

export const {
  toggleDialogSubscriptionAdd,
  setClientId,
  setTab,
  setInputServiceGroupId,
  setPricelist,
  setBackPage,
  setInputPricelistId,
  setPricelists,
  setInputPrice,
  setStart,
  setEnd,
  setComment,
  setDuration,
} = usersPageSlice.actions;

export default clientCardReducer;
