import { ThunkDispatch } from '@reduxjs/toolkit';
import { Client } from '../../services/clients/clients.types';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Box, Button, Tab } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setCurrentPage } from '../applicationPage.slice';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { loadClientData, setTab } from './clientCardPage.slice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { InitialValues } from '../../types/types';
import { getServicesWithDeleted } from '../../store/dictionaries.selectors';
import { fetchClientGroupsDict, fetchRoomsDict, fetchServicesDict, fetchSpecialists } from '../../store/dictionaries.slice';
import clientPhoto from '../../assets/images/client.png';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CelebrationIcon from '@mui/icons-material/Celebration';
import SubscriptionsGrid from './components/SubscriptionsGrid';
import BookingsGrid from './components/BookingsGrid';
import GroupsGrid from './components/GroupsGrid';
import { DateTime } from 'luxon';

export const ClientCardPage = () => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<Client[], any, AnyAction> = useDispatch();
  const {
    clientId,
    tab,
    backPage,
    name,
    phone,
    email,
    instagram,
    telegram,
    whatsapp,
    surname,
    birthDate,
  } = useSelector((state: RootState) => state.clientCard);
  const { studioId } = useSelector((state: RootState) => state.app);
  const servicesWithDeleted = useSelector(getServicesWithDeleted);

  useEffect(() => {
    if (clientId === InitialValues.EMPTY || studioId === InitialValues.EMPTY) {
      navigate(`#clients`);
      dispatch(setCurrentPage('clients'))
    } else {
      dispatch(loadClientData(clientId));
      dispatch(fetchSpecialists(studioId));
      dispatch(fetchRoomsDict(studioId));
      dispatch(fetchClientGroupsDict(studioId));
      if (servicesWithDeleted.length === 0) {
        dispatch(fetchServicesDict());
      }
      dispatch(setTab('1'));
    }
  }, [navigate, clientId, dispatch, servicesWithDeleted.length]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    dispatch(setTab(newValue));
  };

  const handleBack = () => {
    navigate('#' + backPage);
    dispatch(setCurrentPage(backPage as string));
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          display: backPage ? 'flex' : 'none',
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginBottom: '15px',
          paddingBottom: '15px',
          paddingTop: '5px',
          borderBottom: '1px solid green'
        }}
      >
          <Button variant="outlined" startIcon={<ArrowBackIcon />} sx={{ borderRadius: '20px' }} color="primary" onClick={handleBack}>Back</Button>
      </Box>

      <Box sx={{
        display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px',
        padding: '10px 10px'
      }}>
        <Box sx={{ height: '150px', marginRight: '20px' }}>
          <img src={clientPhoto} height={'150px'} />
        </Box>
        <Box sx={{
          display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: '10px', width: '100%'
        }}>
          <Box sx={{ width: '100%', lineHeight: '1.5em' }}>
            <div className={'client-card-row'}>
              <NewspaperIcon/> {name} {surname}
            </div>
            {!!birthDate && (
                <div className={'client-card-row'}>
                  <CelebrationIcon/> Birthday: {DateTime.fromISO(birthDate).toFormat('dd.MM.yyyy')}
                </div>
            )}
            <div className={'client-card-row'}>
              <LocalPhoneIcon/> {phone}
            </div>
            <div className={'client-card-row'}>
              <EmailIcon/> {email}
            </div>
            {!!telegram && (
                <div className={'client-card-row'}>
                  <TelegramIcon/> {telegram}
                </div>
            )}
            {!!whatsapp && (
                <div className={'client-card-row'}>
                  <WhatsAppIcon/> {whatsapp}
                </div>
            )}
            {!!instagram && (
                <div className={'client-card-row'}>
                  <InstagramIcon/>
                  <a href={`https://www.instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer">
                    @{instagram}
                  </a>
                </div>
            )}
          </Box>
          <Box sx={{marginLeft: 'auto'}}>
            ID:&nbsp;{clientId}
          </Box>
        </Box>
      </Box>

      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Subscriptions" value="1" />
            <Tab label="Bookings" value="2" />
            <Tab label="Groups" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SubscriptionsGrid />
        </TabPanel>
        <TabPanel value="2">
          <BookingsGrid />
        </TabPanel>
        <TabPanel value="3">
          <GroupsGrid />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
