import { Box, Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import {
  closePricelistDialogDelete,
  openPricelistDialogAddEdit,
  fetchPricelist,
  openPricelistDialogDelete, loadCurrencyInfo,
} from './pricelistPage.slice';
import { openError, toggleDialogInfo } from '../../components/dialog/dialogInfo.slice';
import { DialogDelete } from '../../components/DialogDelete';
import { Pricelist } from '../../services/pricelist/pricelist.types';
import PricelistService from '../../services/pricelist/pricelist.service';
import currencySign from '../../utils/currencySign';
import { fetchStudiosDict } from '../../store/dictionaries.slice';
import { InitialValues } from '../../types/types';
import { getServiceGroupsWithDeleted } from '../../store/dictionaries.selectors';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PricelistDialogAddEdit from './components/PricelistDialogAddEdit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PricelistPage = () => {
  const dispatch: ThunkDispatch<Pricelist[], any, AnyAction> = useDispatch();

  const {
    data: pricelist,
    dialogs: { delete: { deletingId, isOpen: isDialogDeleteOpened } },
  } = useSelector((state: RootState) => state.pricelist);

  const { studioId } = useSelector((state: RootState) => state.app);
  const serviceGroups = useSelector(getServiceGroupsWithDeleted);

  const handleConfirmDelete = async () => {
    if (!studioId) { dispatch(openError(`Studio not found`)); return; }

    if (deletingId !== null) {
      try {
        await PricelistService.delete(deletingId);
        dispatch(fetchPricelist(studioId));
        dispatch(closePricelistDialogDelete({}));
        dispatch(toggleDialogInfo({
          open: true,
          type: 'info',
          message: 'Pricelist successfully deleted',
        }));
        setTimeout(() => dispatch(toggleDialogInfo({ open: false })), 3000);
      } catch (error) {
        dispatch(toggleDialogInfo({
          open: true,
          type: 'error',
          message: 'Error while delete pricelist: ' + error,
        }));
      }
    } else {
      console.error('Deleting id is not found');
    }
  };

  const columns: GridColDef[] = [{
    field: 'id', // hidden
  },{
    field: 'serviceGroupId',
    headerName: 'Service group',
    flex: 1,
    renderCell: (params: GridRenderCellParams<Pricelist>) => {
      const group = serviceGroups.find((group) => group.id === params.value);
      let groupName = group?.name ?? params.value;
      if (group?.deletedAt) {
        return <span className="strike">{groupName}</span>;
      }
      return groupName;
    }
  },{
    field: 'limit',
    headerName: 'Limit',
    width: 200
  },{
    field: 'freezeDuration',
    headerName: 'Freeze',
    width: 200,
    renderCell: (params: GridRenderCellParams<Pricelist>) => {
      return (params.row.rules.freezeDuration || 0) + ' days';
    }
  },{
    field: 'price',
    headerName: 'Price',
    width: 200,
    renderCell: (params: GridRenderCellParams<Pricelist>) => {
      return params.row.price + ' ' + currencySign(params.row.currency);
    }
  },{
    field: 'duration',
    headerName: 'Duration',
    width: 120,
    renderCell: (params: GridRenderCellParams<Pricelist>) => {
      let duration = '';
      switch (params.row.rules?.duration?.unit) {
        case 'day':
        case 'month':
          duration = params.row.rules.duration?.value + ' ' + params.row.rules.duration.unit + 's';
          break;
        case 'unlimited':
          duration = 'unlimited';
          break;
      }
      return duration;
    }
  },{
    field: 'hidden',
    headerName: 'Visible',
    align: 'center',
    width: 65,
    renderCell: (params) => (
        <>
          {!params.row.hidden && (<VisibilityIcon sx={{ opacity: 0.54 }} />)}
          {params.row.hidden && (<VisibilityOffIcon sx={{ opacity: 0.54 }} />)}
        </>
    ),
  },{
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <>
        <IconButton onClick={ () => dispatch(openPricelistDialogAddEdit({
          mode: 'edit',
          id: params.row.id,
          serviceGroupId: params.row.serviceGroupId,
          durationUnit: params.row.rules.duration?.unit,
          durationValue: params.row.rules.duration?.value,
          freezeDuration: params.row.rules.freezeDuration || 0,
          limit: params.row.limit,
          price: params.row.price,
          hidden: params.row.hidden,
        })) }>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => dispatch(openPricelistDialogDelete(params.row.id))}>
          <DeleteIcon />
        </IconButton>
      </>
    ),
  }];

  useEffect(() => {
    if (studioId !== InitialValues.EMPTY) {
      (async () => {
        await dispatch(fetchStudiosDict());
        await dispatch(fetchPricelist(studioId));
        await dispatch(loadCurrencyInfo());
      })();
    }
  }, [dispatch, studioId]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
            variant="contained"
            sx={{ marginBottom: 1 }}
            onClick={ () => dispatch(openPricelistDialogAddEdit({ mode: 'add' })) }
            startIcon={<AddCircleIcon />}
        >Add pricelist record</Button>
      </Box>

      <DialogDelete
        open={isDialogDeleteOpened}
        question="Are you sure you want to delete pricelist record?"
        onClose={() => dispatch(closePricelistDialogDelete({}))}
        onSubmit={handleConfirmDelete}
      />

      <PricelistDialogAddEdit />

      <DataGrid
        sx={{ '&, [class^=MuiDataGrid-root]': { border: 'none' } }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            }
          }
        }}
        columns={columns}
        hideFooter={true}
        rows={pricelist}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default React.memo(PricelistPage);
